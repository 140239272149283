import "./footer.css";
import logoX from "../../assets/logoX.svg";
import logoTelegram from "../../assets/logoTelegram.svg";
import nyx from "../../assets/Nyxxblacknwhitenobg.png";

const Footer = () => {
	return (
		<footer>
			<div className="footerTop">
				<p className="footerTopItem">You can find us at</p>
				<a className="footerTopItem" href="https://twitter.com/V8N8_events" target="_blank">
					<img className="footerLogo" src={logoX} alt="X logo" />
				</a>
				<a className="footerTopItem" href="https://t.me/V8N8Events" target="_blank">
					<img className="footerLogo" src={logoTelegram} alt="X logo" />
				</a>
			</div>
            <div className="footerBottom">
                <img className="footerBottomItem footerBottomLogo" src={nyx} alt="logo V8N8 Events" />
                <p className="footerBottomItem" >©2024 V8N8 Events</p>
                <p className="footerBottomItem" >Kind regards from Bergen op Zoom, The Netherlands</p>
			</div>
		</footer>
	);
};

export default Footer;
