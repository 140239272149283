import React from "react";
import "./ticketPrices.css";
import TicketCard from "./ticketCard";
import cardBackground from "../../assets/V8N8technobunker2.webp";

const Ticket_prices = () => {
	return (
		<section id="ticketPrices">
		<div className="ticketsPricesContainer">
			<h2 className="ticketContainerTitle">Ticket prices</h2>
			<p className="ticketContainerTitle ticketContainerTitleDesc">
				Join the rave with us!
			</p>
			<div className="ticketPricesArea">
				<TicketCard
					title="Standard"
					titleDesc="A set price"
					price="€10,99"
					priceDesc="Includes VAT and service fees"
					buttonText="Buy your ticket here"
					img={cardBackground}
					sale={"https://www.universe.com/events/bunkerbeats-2024-tickets-0HM8W1"}
				/>
			</div>
		</div></section>
	);
};

export default Ticket_prices;
