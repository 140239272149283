import React, { useState } from "react";
import up from "../../assets/up.svg";
import down from "../../assets/down.svg";

const HarmonicaItem = ({ title, text }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleOpen = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={`harmonicaItem ${isOpen ? "open" : ""}`}>
            <div className="harmonicaTitle" onClick={toggleOpen}>
                <h3>{title}</h3>
                <div className="harmonicaArrow">
                    <img src={isOpen ? up : down} alt={isOpen ? "Up" : "Down"} />
                </div>
            </div>
            {isOpen && (
                <div className="harmonicaContent">
                    <ul>
                        {text.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default HarmonicaItem;
