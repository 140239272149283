import React from "react";
import './schedule.css'; 
import schedule_img from '../../assets/Schedule.svg'
const schedule = () => {
    return (
        <section id="Schedule">
            <div className="schedule">
                <h2>Schedule</h2>
                <img className="scheduleImg" src={schedule_img} alt="" />
            </div>
        </section>
    );
};


export default schedule;