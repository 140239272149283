import React from "react";
import "./location.css";
import delft from "../../assets/IMG_2770.webp";

const location = () => {
	return (
		<>
			<section id="locationInfo">
				<div className="locationContainer">
					<h2 className="locationContainerTitle">Location</h2>
					<div className="locationCards">
						<div className="locationCard">
							<h3>O.J.V. de Koornbeurs</h3>
							<p>
								O.J.V. de Koornbeurs is a youth association located in the
								national monument: De Koornbeurs. The building is only 10
								minutes away of Delft Central Station by foot. Night trains are
								available in the direction of Amsterdam, Utrecht, Rotterdam and
								Eindhoven. We suggest to plan your trip beforehand in the NS
								website or app. Parking garages are also available close to the
								city centre of Delft.
							</p>
							<p>
								There are plenty of food, goods and services close to the venue.
								We recommend to explore the old city centre with several canals
								which is fully packed of Dutch history.
							</p>
							<p>
								Hotels are also available nearby the venue. Please book your
								rooms on time. We observed that there are very limited rooms
								available. Nearby hotels can be found at Google. We do not have
								any partnerships with hotels.
							</p>
							<p>
								Address of the venue: Voldersgracht 1, 2611 ET Delft The
								Netherlands
							</p>
						</div>
						<a href="https://maps.app.goo.gl/oqzoUgiCKRJ3pFPV6" target="_blank">
							<img className="LocationImage"
								src={delft}
								alt="An image of the location"
							/>
						</a>
					</div>
				</div>
			</section>
		</>
	);
};

export default location;
