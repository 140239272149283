import React, {useState, useEffect, useRef} from "react";
import "./navbar.css";
import menu_burger from "../../assets/menu-burger.svg";
import { Link } from 'react-scroll';

const Navbar = () => {
    const [showMenu, setShowMenu]= useState(false);
    const navbarRef = useRef(null);
    const [isScrolled, setIsScrolled] = useState(false);

    /* Add event listener to window */
    useEffect(() => {
        const handleScroll = () => {
            const scrolled = window.scrollY;
            setIsScrolled(scrolled > 20);
        };

        window.addEventListener("scroll", handleScroll); // Add event listener

        return () => window.removeEventListener("scroll", handleScroll); // Cleanup
    }, []); // Empty dependency array ensures effect runs only once

    return (<div ref={navbarRef} className={`Navbar ${isScrolled ? "scrolled" : ""}`}>
        <div>
            <h1 className="NavbarTitle"><Link activeClass="active" to="hero" spy={true} smooth={true} off={-100} duration={500}>V8N8 Events</Link></h1>
        </div>
        <div>
            <ul className="DesktopItems">
                <Link activeClass="active" to="ticketPrices" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Prices</Link>
                <Link activeClass="active" to="LineUp" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Line Up</Link>
                <Link activeClass="active" to="Schedule" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Schedule</Link>
                <Link activeClass="active" to="locationInfo" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Location</Link>
                <Link activeClass="active" to="additionalInfo" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Additional Info</Link>
            </ul>
        </div>
        <div className="burgermenu">
            <img src={menu_burger} alt="menu burger" className="menu-burger" onClick={()=>setShowMenu(!showMenu)}/>
        </div>
        <div className="burgerItems" style={{display: showMenu? 'flex':'none'}}>
            <Link activeClass="active" to="ticketPrices" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Prices</Link>
            <Link activeClass="active" to="LineUp" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Line Up</Link>
            <Link activeClass="active" to="Schedule" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Schedule</Link>
            <Link activeClass="active" to="locationInfo" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Location</Link>
            <Link activeClass="active" to="additionalInfo" spy={true} smooth={true} off={-100} duration={500} className="Gadzooks">Additional Info</Link>
        </div>
    </div>);
};

export default Navbar;
