import React from "react";

const LineupCard = ({ title, text, img, link, platform }) => {
	return (
		<div className="LineupCard">
			<img className="DJImage" src={img} alt="An image of the DJ" />
			<h3>{title}</h3>
			<p className="LineupDesc">{text}</p>
			<a href={link} className="LinkButton LineupButton" target="_blank">{platform}</a>
		</div>
	);
};

export default LineupCard;
