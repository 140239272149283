import React from "react";
import "./bunker.css";
import bunkerBckGrnd from "../../assets/background.png";
import BunkerCard from "./bunkerCard";

const Bunker = () => {
	return (
		<>
			<div className="bunkerBckGrnd">
				<img
					src={bunkerBckGrnd}
					alt="Bunker Background"
					className="bunkerImg"
				/>

				<div className="bunkerCards">
					<div className="info">
						<h3>What's Happening in the bunker?</h3>
						<div className="line"></div>
						<p>
							Some would say, whatever happened in the bunker, stays in the
							bunker. But some secrets got leaked to get you prepared. Get
							ready, for what you will be facing.
						</p>
					</div>
					<div className="infoCards">
						<BunkerCard
							title={"Music and Lights"}
							text={
								"Dude, what else would be in a super secret bunker? Some friendly family reunion? You will see lights you have never seen before. The music fills the room as you are greeted by many flashing lights. The bass going through your body making you move to the beat."
							}
						/>

						<BunkerCard
							title={"Secret Bar"}
							text={
								"What's a bunker without a secret bar? At bunkerBeats we will provide you with the finest drinks to keep you cool and refreshed from all you moves on the dance floor. Or you are just very thirsty. It is a cool place to have a nice chat with your friends too. Quick tip to the drivers: don't drink and drive!"
							}
						/>

						<BunkerCard
							title={"Meet and Greet"}
							text={
								"Many different animals have been gathered at one place. It is never too late to say hello who caught your eye. Have a little chat or even dance together. Don't worry about the predators, they sure won't bite in a techno rave bunker right? Right? Our finest security will make sure to provide the safest place to meet new friends."
							}
						/>

						<BunkerCard
							title={"Furry Lounge"}
							text={
								"We get with everything going on in a techno bunker, sometimes you really need a pause or to cool off. We will provide dedicated furry lounge for the animals to have a little break and gain energy. That is service, without any extra charge at all!"
							}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Bunker;
