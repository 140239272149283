/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import './hero.css'; // Import your CSS file
import hero_img from '../../assets/V8N8bunker168.webp'
import herosmall_img from '../../assets/V8N8technobunker2.webp'

const FullscreenImage = () => {
    return (
        <section id="hero">
        <div className="hero"> {/*I like femboy dragons :)*/}
        <div className="heroContent">
            <div className='heroSection'>
                <h1 className='headtext'>V8N8 Events</h1>
                <h3 className='subtext'>BunkerBeats '1</h3>
                <p>
                    For the 1st edition of BunkerBeats, V8N8 events decided to pull the strobe lights, lasers,
                    dj set and a big sound system out of their storage and throw their first furry techno bunker rave
                    ever.
                </p>
            </div>
            <div className='heroSection'>
                <p>We are the Techno Rave Furs. Grab a drink and party with us!</p>
            </div>
            <div className='heroSection'>
                <p>Don't forget to put it in your calendar!</p>
            </div>
            <div className='heroSection'>
                <h3 className="subsubtext">May 18th 2024</h3>
                <h3 className="subsubtext">O.J.V. de Koornbeurs</h3>
                <h3 className="subsubtext">Thank you all for attending!</h3>
            </div>
            <div className='heroSection'>
                { /* <a href='https://www.universe.com/events/bunkerbeats-2024-tickets-0HM8W1' className="LinkButton heroButton" target="_blank">Buy your ticket here</a> */ }
                <img src={hero_img} alt="Fullscreen Image" className="heroImg fullscreen-image" />
                <img src={herosmall_img} alt='smallscreen Image' className='heroImg smallscreen-image' />
            </div>
        </div>
        </div>
        </section>
    );
};


export default FullscreenImage;