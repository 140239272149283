import React from "react";

const TicketCard = ({
	title,
	titleDesc,
	price,
	priceDesc,
	buttonText,
	img,
	sale,
}) => {
	return (
		<a href={sale} className="ticketCard" target="_blank">
			<div className="ticketCardSection">
				<p className="TicketTitle">{title}</p>
				<p className="ticketTitleDesc">{titleDesc}</p>
			</div>
			<div className="ticketCardSection">
				<p className="ticketPrice">{price}</p>
				<p className="ticketPriceDesc">{priceDesc}</p>
			</div>
			<div className="ticketCardSection">
				{ /* <button className="ticketButton">{buttonText}</button> */ }
				<img className="TicketImg" src={img} alt="card background" />
			</div>
		</a>
	);
};

export default TicketCard;
