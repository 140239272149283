import Navbar from "../componets/navbar/navbar";
import Bunker from "../componets/bunker/bunker";
import TicketPrices from "../componets/ticket_prices/ticketPrices";
import Hero from "../componets/hero/hero";
import Lineup from "../componets/lineup/lineup";
import Location from "../componets/location/location";
import Additional from "../componets/aditional_info/aditional";
import Footer from "../componets/footer/footer";
import Schedule from "../componets/schedule/schedule";
function App() {
    return (
        <div className="App">
            <Navbar/>
            <Hero/>
            <Bunker/>
            <TicketPrices/>
            <Lineup/>
            <Schedule/>
            <Location/>
            <Additional/>
            <Footer/>
        </div>
    );
}


export default App;
