import React from "react";

const BunkerCard = ({ title, text }) => {
	return (
		<div className="card">
			<h3>{title}</h3>
			<p>{text}</p>
		</div>
	);
};

export default BunkerCard;
