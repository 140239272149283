import React from "react";
import "./lineup.css";
import arkifel from "../../assets/arkifel.jpeg";
import saigai from "../../assets/saigai_1.webp";
import vegaz from "../../assets/vegaz.jpg";
import anonymoux from "../../assets/anonymoux.jpg";
import LineupCard from "./lineupCard";

const Lineup = () => {
	return (
		<>
			<section id="LineUp">
			<div className="lineupCardContianer">
				<h2 className="LineupContainerTitle">DJ Lineup</h2>
				<div className="LineupCards">
					<LineupCard
						img={saigai}
						title={"Saigai"}
						text={
							"Saigai is known for his energetic performances, where he mixes Drum & Bass, Dubstep and orchestral sounds to create a dark and epic atmosphere. After having played at Pawske Party and Otterdance, he is no stranger to the furry rave scene. He'll keep you guessing and moving along to his music!"
						}
						platform={"YouTube"}
						link={"https://youtu.be/wv-nvSD26bY?si=9hgl4pB9hC9A7YEB"}
					/>
					<LineupCard
						img={arkifel}
						title={"Arkifel"}
						text={
							"Arkifel brings us a set full of electro and bass house guaranteed to get your booty moving! They've played at Otterdance, Alfurnative, and hacker events around Amsterdam. Arkifel also produces house music and chiptunes, so you might hear a few fresh tracks too. You don’t want to miss it!"
						}
						platform={"PeerTube"}
						link={"https://tube.tchncs.de/w/wnh4wFcBAx1KA27xa65SJB"}
					/>
					<LineupCard
						img={vegaz}
						title={"Vegaz"}
						text={
							"Vegaz will bring you various types of Drum & Bass. Ranging from some hard Belgian jump up, to some calmer liquid. Having loads of experience by having played at places such as: Nordic Fuzz Con and Otterdance!  He will be sure to get your tails swinging!"
						}
						platform={"SoundCloud"}
						link={"https://on.soundcloud.com/rCzSQHRhnRP85HQ69"}
					/>
					<LineupCard
						img={anonymoux}
						title={"Anonymoux"}
						text={
							"DJ Anonymoux, a Dutch DJ with a heart for House, Dance and hardstyle. Starting as a hobby that got a little out of hand, he will now bring a set full of the best Tech House, Bass House and Mashups to get everyone on their feet! Having played at events such as Alfurnative and NordicFuzzCon, he truly knows his way around the mixer!"
						}
						platform={"Instagram"}
						link={"https://www.instagram.com/LucasDutchFloof/"}
					/>
				</div>
			</div>
			</section>
		</>
	);
};

export default Lineup;
