import "./aditional.css";
import HarmonicaItem from "./harmonicaItem";

const Aditional = () => {
	return (
		<section id="additionalInfo">
		<div className="aditional">
			<div className="aditionalText">
				<h2>Terms and Conditions</h2>
				<p>We want to keep a safe and fun environment for everyone at our events.
					To enforce this, we have set up rules. Please keep yourself familiar with these rules.
					Most rules are based on common sense and must always be followed.
					Please also listen to additional instructors of our staff at all times.
					For any questions or comments about the rules or our staff,
					please contact us at: v8n8events@gmail.com or approach the head of security or the chairman at the location.</p>
			</div>
			<div className="aditionalHarmonica">
				<HarmonicaItem
				title="General"
				text={[
					"You must be 18 years or older at the date of the event.",
					"Any violation of the rules could result in immediate removal from the event and potentially being blacklisted from future events.",
					"Staff are allowed to enforce additional measures to ensure the safety of all attendees."
				]}
				/>
				<HarmonicaItem
					title="Registration"
					text={[
						"Tickets are only sold online at: https://www.universe.com/events/bunkerbeats-2024-tickets-0HM8W1.",
						"Only staff and attendees with a valid ticket are allowed to be inside of the venue.",
						"Attendees are required to wear their badge at all times.",
						"Badges need to be shown upon request by staff.",
						"We only give refunds till one week before the event. After this deadline, the tickets can be swapped until one day before the event date. Both parties need to contact us through email and wait for our approval."
					]}
				/>
				<HarmonicaItem
					title="Clothing and costumes"
					text={[
						"Keep costumes at “16” (CAT.2 / PG-13) level or below. We describe it as follows: Pup masks, rubber/vinyl/lycra, and harnesses are allowed as long as they don't expose or accentuate genitalia or buttocks. Attendees may not wear clothing or accessories which are overly revealing, inappropriate to the atmosphere of the event, or likely to draw reasonable complaints. This includes clothing or accessories that are fetish-related. BunkerBeats '1 is not a fetish event. If you have doubts, please contact us in advance.",
						"Entry to the “Fuirsuit lounge” is for fursuiters and only with up to one assistant.",
						"Please do not take photos and videos in the “Fursuit lounge”",
						"No glitter or body paint. These are likely to damage others' costumes and the venue.",
						"We do not tolerate sexual behaviour that goes beyond a mere display of affection."
					]}
				/>
				<HarmonicaItem
					title="Venue"
					text={[
						"It is prohibited to bring/consume (alcoholic) drinks you bring with you except water.",
						"No nuisance, violence, unruly drunkenness or destruction.",
						"Respect the volunteers of the venue.",
						"The venue's doormen reserve the right to refuse/remove people without giving any reason.",
						"Ignoring these rules may lead to removal from the premises.",
						"Absolutely no drugs (soft and hard) are allowed. We will notify the local authorities and the venue if we find you in possession of drugs.",
						"Smoking and vaping are only allowed outside the venue. We do not have designated smoking areas."
					]}
				/>
				<HarmonicaItem
					title="Photography and video"
					text={[
						"V8N8 Events and O.J.V. de Koornbeus reserve all rights to video and photo material gathered at the venue. V8N8 Events grants you the right to use videos and photos you have taken at the convention for private and/or non-commercial purposes.",
						"Publication in for-profit media requires written permission from the Board of V8N8 Events and O.J.V. de Koornbeurs"
					]}
				/>
			</div>
		</div>
		</section>
	);
};

export default Aditional;
